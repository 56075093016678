import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../components/indexBasicsComponents';
import '../CSS/SignUp.css';
import { SignupForm } from '../components/indexAccountsComponents';
import { faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons';

function SignUp() {
  const { type } = useParams();

  return (
    <div className='temp2'>
      <div className="backArrow">
        <Button icon={faArrowAltCircleLeft} route={"/"}/>
      </div>
      <SignupForm />
    </div>
  )
}

export default SignUp
