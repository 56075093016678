import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { Accueil, Login, SignUp, Profil, Feed, Match, Etudiant, MatchedStudents, StudentRanking, Maintenance } from './pages/indexPages';
import { ProtectedRoute } from './components/indexAccountsComponents';
import { AuthProvider } from './context/AuthContext';

function App() {
  // Ajoutez un état pour contrôler le mode maintenance
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  // Fonction pour activer/désactiver le mode maintenance
  const toggleMaintenanceMode = () => setIsMaintenanceMode(!isMaintenanceMode);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {isMaintenanceMode ? (
            // Si le mode maintenance est activé, redirigez toutes les routes vers la page de maintenance
            <Route path="*" element={<Maintenance />} />
          ) : (
            // Sinon, affichez les routes normales de votre application
            <>
              <Route path="/" element={<Accueil/>}/>
              <Route path="/connexion" element={<Login/>}/>
              <Route path="/inscription" element={<SignUp/>}/>
              <Route path="/profil" element={<ProtectedRoute><Profil/></ProtectedRoute>}/>
              <Route path="/profil/entreprise/:businessId" element={<ProtectedRoute><Profil/></ProtectedRoute>}/>
              <Route path="/profil/etudiant/:studentId" element={<ProtectedRoute><Profil/></ProtectedRoute>}/>
              <Route path="/match" element={<ProtectedRoute><Match/></ProtectedRoute>}/>
              <Route path="/feed" element={<ProtectedRoute><Feed/></ProtectedRoute>}/>
              <Route path="/etudiant" element={<ProtectedRoute><Etudiant/></ProtectedRoute>}/>
              <Route path="/match-etudiant" element={<ProtectedRoute><MatchedStudents/></ProtectedRoute>}/>
              <Route path="/classement" element={<StudentRanking/>}/>
              <Route path="/maintenance-en-cour" element={<Maintenance />} />
            </>
          )}
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
