import React from 'react';
import {unimatchLogoPng} from '../img/index';
import '../CSS/Maintenance.css';

function Maintenance() {
  return (
    <div className="container text-center d-flex flex-column my-5 py-5 Maintenance">
      <h1 className="mb-4">Maintenance en cours</h1>
      <p className="mb-4">Nos serveurs sont actuellement en maintenance. Nous travaillons pour rétablir le service le plus rapidement possible. Merci de votre patience.</p>
      <img src={unimatchLogoPng} alt="Maintenance" className="img-fluid mb-4" />
      <div>
        <button className="btn rouge-bg text-white" onClick={() => window.location.href = '/'}>Rafraîchir</button>
      </div>
    </div>
  );
}

export default Maintenance;
