import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom';

function Button({ icon, route, className, type, text, onClick }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick(); // Si une fonction onClick est fournie, l'exécuter
    }
    if (route) {
      navigate(route); // Naviguer vers la route spécifiée
    }
  }

  return (
    <button 
      className={className} 
      type={type}
      onClick={handleClick}>
        {text}
        {icon ? <FontAwesomeIcon icon={icon}/> : ''}
    </button>
  )
}

export default Button

