import React, { useState, useEffect } from 'react';
import { Navbar } from '../components/indexBasicsComponents'; // Assurez-vous que le chemin est correct
import config from '../settings.json'; 


function MatchedStudents() {
    const [students, setStudents] = useState([]);
    const userStr = localStorage.getItem("user");
    const userObj = JSON.parse(userStr);
    const token = userObj ? userObj.token : null;

    const [searchTerm, setSearchTerm] = useState('');


    const updateMatchStatus = async (matchId, newStatus) => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/matches/${matchId}/status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus }),
            });
            if (response.ok) {
                // Mettez à jour l'état local pour refléter le changement de statut
                setStudents(prevStudents => prevStudents.map(match => {
                    if (match.id === matchId) {
                        return { ...match, status: newStatus };
                    }
                    return match;
                }));
            } else {
                console.error("Erreur lors de la mise à jour du statut");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut:", error);
        }
    };

    useEffect(() => {
        fetchMatchedStudents();
    }, []);

    const fetchMatchedStudents = async () => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/businesses/matchedStudents`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setStudents(data);
            console.log('DATA : ', data);
        } catch (error) {
            console.error("Erreur lors de la récupération des étudiants matchés:", error);
        }
    };

    function truncateString(str, num) {
        if (str.length > num) {
          return str.slice(0, num) + '...';
        } else {
          return str;
        }
      }

    return (
        <>
        <div className="container mt-5 d-flex flex-column Etudiant">
            <h2 className='rouge'>Étudiant(es) Matchés</h2>
            <input
                type="text"
                className="form-control mb-4"
                placeholder="Rechercher un étudiant..."
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="student-container">
            {students
              .filter(match => {
                // Convertissez en minuscules pour une comparaison insensible à la casse
                const fullNameLower = match.Student.fullName.toLowerCase();
                const searchLower = searchTerm.toLowerCase();
                // Comparez si le nom contient le terme de recherche
                return fullNameLower.includes(searchLower);
              })
              .map((match, index) => (
                <div key={index} className="col-md-4 mb-3 card-div">
                    {match.Student && (
                    <div className="card h-100">
                        <div className="card-img-container position-relative">
                            <img src={match.Student.profileImageUrl === '' || match.Student.profileImageUrl === null ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png' :`https://backend.unimatch.ca/${match.Student.profileImageUrl}`} className="card-img-top" alt="Profile" />
                        </div>
                        <div className="card-body">
                        <div className="card-img-overlay d-flex align-items-end position-relative mb-3 p-0">
                                <h5 className="card-title rouge-bg text-white w-100 text-center py-2 mb-0">{match.Student.fullName}</h5>
                            </div>
                            <p className="card-text">{match.Student.Role}</p>
                            <p className="card-text">{truncateString(match.Student.description || "Aucune description disponible.", 90)}</p>
                            {match.status === 'pending' && (
                                <button className="btn rouge-bg font-size-small" onClick={() => updateMatchStatus(match.id, 'in interview')}>
                                    Prendre en Interview
                                </button>
                            )}
                            {match.status === 'in interview' && (
                                <>
                                    <button className="btn btn-success me-2" onClick={() => updateMatchStatus(match.id, 'accepted')}>
                                        Approuver
                                    </button>
                                    <button className="btn btn-danger" onClick={() => updateMatchStatus(match.id, 'refused')}>
                                        Refuser
                                    </button>
                                </>
                            )}
                            <p>Status : {match.status === "accepted" && 'Accepté(e)'}{match.status === "refused" && 'Refusé(e)'}</p>
                            <a href={`/profil/etudiant/${match.Student.id}`} className="btn rouge-bg text-white">Voir le profil</a>
                        </div>
                    </div>
                    )}
                </div>
            ))}
            </div>
        </div>
        <Navbar/>
        </>

    );
}

export default MatchedStudents;
