import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from '../components/indexBasicsComponents';
import '../CSS/Etudiant.css';
import config from '../settings.json'; 


function Etudiant() {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const userStr = localStorage.getItem("user");
  const userObj = JSON.parse(userStr);
  const token = userObj ? userObj.token : null;

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/students`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      const data = await response.json();
      console.log("here", data);
      setStudents(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des étudiants:", error);
    }
  };

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  }

  return (
    <>
      <Navbar/>
      <div className="container mt-5 Etudiant">
          <input
              type="text"
              className="form-control mb-4"
              placeholder="Rechercher un étudiant..."
              onChange={(e) => setSearchTerm(e.target.value)}
          />
        <div className="student-container">
            {students.filter((student) =>
            student.fullName.toLowerCase().includes(searchTerm.toLowerCase())
            ).map((student, index) => (
            <div key={index} className="card-div">
              <div className="card h-100">
                <div className="card-img-container position-relative">
                    <img src={student.profileImageUrl === '' || student.profileImageUrl === null ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png' :`https://api.unimatch.ca/${student.profileImageUrl}`} className="card-img-top" alt="Profile" />
                    <div className="card-img-overlay d-flex align-items-end p-0">
                        <h5 className="card-title rouge-bg text-white w-100 text-center py-2 mb-0">{student.fullName}</h5>
                    </div>
                </div>
                <div className="card-body">
                    <p className="card-text"> {student.Role || "Non spécifié"}</p>
                    <p className="card-text">
                      <strong>Description: </strong> 
                      {truncateString(student.description || "Aucune description disponible.", 70)}
                    </p>
                    <a href={`/profil/etudiant/${student.id}`} className="btn rouge-bg text-white">Voir le profil</a>
                </div>
              </div>
            </div>
            ))}
        </div>
    </div>
    </>
  );
}

export default Etudiant;
