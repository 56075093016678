import React, { /* useContext, */ useState } from 'react';
import { useNavigate } from 'react-router-dom';
/* import { AuthContext } from '../../App'; */ // Import the AuthContext
import {Button, TextInput} from '../indexBasicsComponents';
import '../../CSS/LoginForm.css';
import { unimatchLogoPng } from '../../img/index';
import { useAuth } from '../../context/AuthContext';
import config from '../../settings.json'; 


function LoginForm() {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { login } = useAuth();
  const navigate = useNavigate();

  // Add the following line to access the setIsAuthenticated function from the context
  /* const { setIsAuthenticated, redirectLink } = useContext(AuthContext); */

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    // Vérifiez si l'un des champs est vide
    if (!formData.email || !formData.password) {
      setErrorMessage("Veuillez remplir tous les champs.");
      return; // Arrêtez l'exécution de la fonction si un champ est vide
    }
  
    try {
      const response = await fetch(`${config.apiBaseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Une erreur s'est produite lors de la connexion.");
      }
  
      login(data); // Stocker les données de l'utilisateur et le token JWT
      navigate('/profil'); // Redirigez vers la page de profil
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
    }
  };
  
  

  return (
    <div className="LoginForm">
      <div className="top">
        <img src={unimatchLogoPng} className='home-logo' alt="logo unimatch" />
      </div>
      <form onSubmit={handleSubmit}>
        <h1>Se Connecter</h1>
        <p className='error-msg'>{errorMessage}</p>
        <TextInput
          labelText="Courriel"
          name="email"
          type="email"
          placeholder="Courriel"
          value={formData.email}
          onChange={handleChange}
        />
        <TextInput
          labelText="Mot de passe"
          name="password"
          type="password"
          placeholder="mot de passe"
          value={formData.password}
          onChange={handleChange}
        />
      {/* <Button route="" divClass="btn-login" type="submit" text="Se Connecter"></Button> */}
      <button type='submit'>Se Connecter</button>
    </form>
    <div className='signupBox'>
        <h2>Pas de compte ?</h2>
        <Button route="/inscription" type="" text="Créer un compte"></Button>
    </div>
</div>
)
}

export default LoginForm;
