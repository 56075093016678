import React, {useState, useEffect} from 'react';
import { useSwipeable } from 'react-swipeable';
import { Navbar } from '../components/indexBasicsComponents';
import '../CSS/Feed.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faHeart, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {tecnovaBanner} from '../img/index';
import { useNavigate } from 'react-router-dom';
import config from '../settings.json'; 


function Feed() {
  const [entreprises, setEntreprises] = useState([]);
  const [currentEntrepriseIndex, setCurrentEntrepriseIndex] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [swipeDirection, setSwipeDirection] = useState('');
  const [isPopupDisplayed, setIsPopupDisplayed] = useState(false);

  const [hasMoved, setHasMoved] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);

  const [left, setLeft] = useState(0);
  const [velocity, setVelocity] = useState(0);
  const [beingTouched, setBeingTouched] = useState(false);
  const [lastTime, setLastTime] = useState(0);

  const userStr = localStorage.getItem("user");
  const userObj = JSON.parse(userStr);
  const token = userObj ? userObj.token : null;

  const navigate = useNavigate();


  const handleMouseDown = (e) => {
    setIsDragging(true);
    setBeingTouched(true);
    setStartX(e.clientX);
    setLeft(0);
    setVelocity(0);
    setCurrentX(0); // Réinitialiser la position actuelle
    setLastTime(Date.now()); // Réinitialiser le temps
  };

  const handleMouseMove = (e) => {
    if (isDragging && beingTouched) {
      setHasMoved(true); // Indique qu'un mouvement a eu lieu
      const touchX = e.clientX;
      const currTime = Date.now();
      const elapsed = currTime - lastTime;
      const velocity = 20 * (touchX - startX) / elapsed;
      const deltaX = touchX - startX;
      setLeft(deltaX);
      setVelocity(velocity);
      setLastTime(currTime);
    }
  };

  const handleProfileClick = (businessId) => {
    navigate(`/profil/entreprise/${businessId}`);
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
    setBeingTouched(false);
    setCurrentX(0);
    setVelocity(0);
  
    if (left > 200 && hasMoved) {
      like();
    } else if (left < -200 && hasMoved) {
      dislike();
    } else {
      animateSlidingToZero();
    }
  
    // Réinitialiser hasMoved et left pour le prochain mouvement
    setHasMoved(false);
    setLeft(0);
  };
  


  const animateSlidingToZero = () => {
    let animationFrame;
    const animate = () => {
      setLeft((prevLeft) => {
        if (prevLeft > 1 || prevLeft < -1) {
          const newLeft = prevLeft - velocity;
          setVelocity((prevVelocity) => prevVelocity * 0.95); // Décélération
          animationFrame = requestAnimationFrame(animate);
          return newLeft;
        } else {
          return 0; // Arrêter l'animation
        }
      });
    };
    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  };
  
  const cardStyle = {
    transform: `translateX(${left}px) rotate(${left / 20}deg)`,
    transition: beingTouched ? 'none' : 'transform 300ms ease-out'
  };
  

  useEffect(() => {
    fetchEntreprise();
    }, []);

    const fetchEntreprise = async () => {
      // Vérifier si les données des entreprises sont déjà stockées dans localStorage
      const savedEntreprises = localStorage.getItem('entreprises');
      
      if (savedEntreprises) {
        // Si les données existent, les convertir de la chaîne JSON en objet JavaScript et les utiliser
        setEntreprises(JSON.parse(savedEntreprises));
      } else {
        // Si les données n'existent pas, fetcher les données de l'API
        try {
          const response = await fetch(`${config.apiBaseUrl}/businesses`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          });
          const data = await response.json();
          
          // Stocker les données fetchées dans localStorage après les avoir converties en chaîne JSON
          localStorage.setItem('entreprises', JSON.stringify(data));
          
          // Mettre à jour l'état avec les données fetchées
          setEntreprises(data);
        } catch (error) {
          console.error("Erreur lors de la récupération des entreprises:", error);
        }
      }
    }
    

  const moveToNextEntreprise = (direction, feedback) => {
    setSwipeDirection(direction);
    setFeedback(feedback);
  
    setTimeout(() => {
      // Vérifie si l'index actuel est le dernier de la liste
      if (currentEntrepriseIndex >= entreprises.length - 1) {
        // Réinitialise l'index pour recommencer au début de la liste
        setCurrentEntrepriseIndex(0);
      } else {
        // Sinon, passe simplement à l'entreprise suivante
        setCurrentEntrepriseIndex(currentEntrepriseIndex + 1);
      }
      setSwipeDirection('');
      setFeedback('');
      setCurrentX(0);
    }, 500);
  };

  const moveToPreviousEntreprise = () => {
    setTimeout(() => {
      // Vérifie si l'index actuel est le premier de la liste
      if (currentEntrepriseIndex === 0) {
        // Réinitialise l'index pour recommencer a la fin de la liste
        setCurrentEntrepriseIndex(entreprises.length - 1);
      } else {
        // Sinon, passe simplement à l'entreprise d'avant
        setCurrentEntrepriseIndex(currentEntrepriseIndex - 1);
      }
    }, 200);
  };
  const moveQuickToNextEntreprise = () => {
    setTimeout(() => {
      // Vérifie si l'index actuel est le dernier de la liste
      if (currentEntrepriseIndex >= entreprises.length - 1) {
        // Réinitialise l'index pour recommencer au début de la liste
        setCurrentEntrepriseIndex(0);
      } else {
        // Sinon, passe simplement à l'entreprise suivante
        setCurrentEntrepriseIndex(currentEntrepriseIndex + 1);
      }
    }, 200);
  };


  const like = () => {
    setIsPopupDisplayed(true);
  };

  const dislike = () => {
    moveToNextEntreprise('swiping-left', 'Entreprise suivante !');
  };

  const handlers = useSwipeable({
    onSwipedLeft: dislike,
    onSwipedRight: like,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  }

  const verifyPin = async (pin) => {
    const entreprise = entreprises[currentEntrepriseIndex];
    const studentId = userObj.userId; // Assurez-vous que userObj contient bien userId
  
    // Vérifiez si le PIN correspond
    if (entreprise.pinCode.toString() === pin) {
      try {
        // Créez une entrée dans la table matches
        const response = await fetch(`${config.apiBaseUrl}/matches`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            studentId,
            businessId: entreprise.id,
            hasBeenDone: false,
            status: 'pending'
          }),
        });
        const data = await response.json();
  
        if (response.ok) {
          saveMatchToLocalStorage(entreprise);
          moveToNextEntreprise('swiping-right', 'Entreprise Enregistrée !');

          // Supprimez l'entreprise du feed et de localStorage
          const updatedEntreprises = entreprises.filter((_, index) => index !== currentEntrepriseIndex);
          
          // Mettre à jour localStorage avec les nouvelles entreprises
          localStorage.setItem('entreprises', JSON.stringify(updatedEntreprises));
          
          // Mettre à jour l'état avec les nouvelles entreprises
          setEntreprises(updatedEntreprises);

          // Déplacez-vous vers la prochaine entreprise, si disponible
          setCurrentEntrepriseIndex(current => current >= updatedEntreprises.length ? 0 : current);

        } else {
          console.error('Erreur lors de la création du match:', data);
          alert('Erreur lors de la création du match');
        }
      } catch (error) {
        console.error('Erreur lors de la création du match:', error);
      }
    } else {
      alert('PIN incorrect');
    }
    setIsPopupDisplayed(false); // Fermez le popup après la vérification
  };

  const saveMatchToLocalStorage = (entreprise) => {
    // Obtenez la liste actuelle des matchs à partir de localStorage ou initialisez-la si elle n'existe pas
    const currentMatches = JSON.parse(localStorage.getItem('matches')) || [];
    // Ajoutez le nouveau match à la liste
    currentMatches.push(entreprise);
    // Enregistrez la liste mise à jour dans le localStorage
    localStorage.setItem('matches', JSON.stringify(currentMatches));
  };
  


    return (
<div className='Feed'>
  <Navbar/>
  <div className="feed-container" onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
    <p className="tecnova">Propulsé par <img src={tecnovaBanner} alt="" /></p>
    {entreprises.length > 0 ? (
      <div 
        {...handlers} 
        className={`entreprise-card ${swipeDirection}`} 
        style={isDragging ? cardStyle : null}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}>
        <h2>{entreprises[currentEntrepriseIndex].businessName}</h2>
        <div onClick={() => handleProfileClick(entreprises[currentEntrepriseIndex].id)}>
          <img 
            src={`https://backend.unimatch.ca/${entreprises[currentEntrepriseIndex].profileImageUrl}`} 
            alt="logo" 
          />
        </div>
        {entreprises[currentEntrepriseIndex].description && <p>{truncateString(entreprises[currentEntrepriseIndex].description || "Aucune description disponible.", 90)}</p>}
        <div className="btn-container">
          <button className="dislike" onClick={dislike}><FontAwesomeIcon icon={faXmark} /></button>
          <button className="like" onClick={like}><FontAwesomeIcon icon={faHeart} /></button>
        </div>
      </div>
    ) : (
      // Message affiché lorsque la liste des entreprises est vide
      <div className="no-more-entreprises">
        <h2>Vous avez swipé toutes les entreprises de l'événement!</h2>
        <p>Revenez plus tard pour voir de nouvelles entreprises.</p>
      </div>
    )}
    <div className="indicator">

      <FontAwesomeIcon icon={faChevronLeft} onClick={moveToPreviousEntreprise} />
      <p className='msg'>Voir d'autres entreprises</p>
      <FontAwesomeIcon icon={faChevronRight} onClick={moveQuickToNextEntreprise} />
    </div>
  </div>
  {isPopupDisplayed && (
    <div className="pin-popup">
      <div className="pin-container">
        <div className="close-btn">
          <button onClick={() => setIsPopupDisplayed(false)}><FontAwesomeIcon icon={faXmark}/></button>
        </div>
        <div>
          <h2>Veuillez entrer le PIN de l'entreprise</h2>
          <p className='small-text'>(Rendez-vous au kiosque de l'entreprise afin de récupérer votre PIN)</p>
          <input type="text" id="pinInput" className='w-100
          '/>
        </div>
        <button onClick={() => verifyPin(document.getElementById('pinInput').value)}>Confirmer</button>
      </div>
    </div>
  )}
  <p className='feedback'>{feedback}</p>
</div>
    )
    }
    
    export default Feed;
    