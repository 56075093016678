import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assurez-vous d'avoir installé axios
import "../CSS/StudentRanking.css";
import {tecnovaBanner, logoComite} from '../img/index';
import config from '../settings.json';   

function StudentRanking() {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        const { data } = await axios.get(`${config.apiBaseUrl}/students/ranking`);

        const excludedStudentIds = [3, 9, 7, 10, 13, 15, 5];
        // Filtrer les étudiants pour exclure ceux dont l'ID est dans excludedStudentIds
        const filteredStudents = data.filter(student => !excludedStudentIds.includes(student.id));
        setStudents(filteredStudents);
      } catch (error) {
        console.error("Erreur lors de la récupération du classement:", error);
      }
    };

    // etudiant a caché : 9 7 4 12 18 5 13

    fetchRanking();

    // Définir un intervalle pour rafraîchir le classement toutes les 5 minutes (300000 millisecondes)
    const intervalId = setInterval(fetchRanking, 150000); // 300000 ms = 5 minutes

    // Nettoyer l'intervalle quand le composant est démonté ou re-monté
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container mt-5 StudentRanking">
      <div className="logo mb-4"><img src={tecnovaBanner} alt="" /></div>
      <h1 className='rouge'>Université de laval</h1>
      <h2>Classement des étudiants</h2>
      <ul className="list-group">
        {students.map((student, index) => (
          <li key={student.id} className={`list-group-item d-flex align-items-center ${index < 3 ? 'font-weight-bold' : ''}`}>
            <img src={student.profileImageUrl === null ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" : `https://backend.unimatch.ca/${student.profileImageUrl}`} alt="Profile" className="rounded-circle mr-3" style={{ width: '75px', height: '75px' }} />
            <div className='info'>
              <h5 className="mb-0">{student.fullName}</h5>
              <small>Points: {student.score}</small>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default StudentRanking;
