import React from 'react';
/* import { useNavigate } from 'react-router-dom';
import { Button } from '../components/indexBasicsComponents'; */
import { LoginForm } from '../components/indexAccountsComponents';
import { Button } from '../components/indexBasicsComponents';
import '../CSS/Login.css';
import { faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons'

function Login() {

    
  return (
    <div className='Login'>
      <div className="backArrow">
        <Button icon={faArrowAltCircleLeft} route={"/"}/>
      </div>
      <LoginForm/>
    </div>
  )
}

export default Login