import React from 'react';
import {Button} from '../indexBasicsComponents';
import '../../CSS/Navbar.css';
import { faUsersViewfinder, faCommentDots, faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import {useAuth} from '../../context/AuthContext';

function Navbar() {
  const { currentUser, logout } = useAuth();


  return (
    <div className='menu'>
      {currentUser && currentUser.userType === 'student' && (
        <>
        <Button route={"/feed"} icon={faUsersViewfinder}/>
        <Button route={"/match"} icon={faCommentDots} className={'border-l'}/>
        <Button route={"/profil"} icon={faUser} className={"border-l-r"}/>
        <Button route={"/"} icon={faRightFromBracket} onClick={logout}/>
        </>
      )}
      {currentUser && currentUser.userType === 'business' && (
        <>
        <Button route={"/etudiant"} icon={faUsersViewfinder}/>
        <Button route={"/match-etudiant"} icon={faCommentDots} className={'border-l'}/>
        <Button route={"/profil"} icon={faUser} className={"border-l-r"}/>
        <Button route={"/"} icon={faRightFromBracket} onClick={logout}/>
        </>
      )}
    </div>
  )
}

export default Navbar