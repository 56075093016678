import React, { useState, useEffect } from 'react';
import { Navbar } from '../components/indexBasicsComponents';
import '../CSS/Match.css';
import { useNavigate } from 'react-router-dom';
import config from '../settings.json'; 

function Match() {
  const [matches, setMatches] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const userStr = localStorage.getItem("user");
  const userObj = JSON.parse(userStr);
  const token = userObj ? userObj.token : null;
  const navigate = useNavigate();

  useEffect(() => {
      fetchMatches();
  }, []);

  const showCancelConfirmation = (matchId) => {
    setSelectedMatchId(matchId);
    setShowConfirmationPopup(true);
  };

  const cancelMatch = async () => {
    if (!selectedMatchId) return;
    try {
      const response = await fetch(`${config.apiBaseUrl}/matches/${selectedMatchId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ status: 'canceled' }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'annulation du match');
      }

      const updatedMatches = matches.filter(match => match.id !== selectedMatchId);
      setMatches(updatedMatches);
      setShowConfirmationPopup(false); // Fermer la popup après l'annulation
      alert('Match annulé avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'annulation du match:', error);
      alert('Erreur lors de l\'annulation du match.');
    }
  };

  const fetchMatches = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/matches`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      
      // Filtrer les matchs pour exclure ceux avec le statut 'canceled'
      const filteredMatches = data.filter(match => match.status !== 'canceled');
      
      setMatches(filteredMatches);
    } catch (error) {
      console.error("Erreur lors de la récupération des matchs:", error);
    }
  }

  const getStatusText = (status) => {
    switch (status) {
      case 'accepted':
        return 'Accepté';
      case 'refused':
        return 'Refusé';
      case 'canceled':
        return 'Annulé';
      default:
        return 'En attente';
    }
  };
  

  return (
    <div className='Match Etudiant'>
      <Navbar/>
      <h1>Entreprise(s) enregistré(es)</h1>
      <div className="matches-container">
        {matches.length > 0 ? (
          matches.map((match, index) => (
            <div key={index} className='card-div match-card'>
              <div className={`card h-100 ${match.status}`}>
                <div className="pdp-container card-img-container position-relative">
                  <img src={`https://backend.unimatch.ca/${match.Business.profileImageUrl}`} alt="logo" className="card-img-top mb-5" />
                  {/* <div className="card-img-overlay d-flex align-items-end p-0">
                      <h5 className="card-title rouge-bg text-white w-100 text-center py-2 mb-0">{match.Business.businessName}</h5>
                  </div> */}
                </div>
                  <div className="card-img-overlay d-flex align-items-end position-relative mb-3 p-0">
                    <h5 className="card-title rouge-bg text-white w-100 text-center py-2 mb-0">{match.Business.businessName}</h5>
                  </div>
                  <p className={`status ${match.status}`}> Status : {getStatusText(match.status)}</p>
                <div className="match-actions pb-3">
                  <button className='bleu-bg' onClick={() => navigate(`/profil/entreprise/${match.businessId}`)}>Voir le profil</button>
                  <button className='rouge-bg' onClick={() => showCancelConfirmation(match.id)}>Annulé le match</button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun match</p>
        )}
      </div>
      {showConfirmationPopup && (
        <div className="confirmation-popup">
          <p>Êtes-vous sûr de vouloir annuler ce match ?</p>
          <div className="d-flex justify-content-center gap-3">
            <button onClick={cancelMatch}>Confirmer</button>
            <button onClick={() => setShowConfirmationPopup(false)}>Annuler</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Match;
