import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextInput } from '../indexBasicsComponents';
import { unimatchLogoPng } from '../../img/index';
import '../../CSS/SignupForm.css';
import config from '../../settings.json'; 


function SignUpForm() {
  const [formData, setFormData] = useState({
    accountType: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    setIsLoading(true); // Activez l'animation de chargement

    try {
      const response = await fetch(`${config.apiBaseUrl}/signup/${formData.accountType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 409) {
          throw new Error("Cet email est déjà utilisé.");
        }
        throw new Error(data.message || "Une erreur s'est produite lors de l'inscription.");
      }
  
      // Gestion de la réponse réussie
      navigate('/profil'); // Redirigez l'utilisateur
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false); // Désactivez l'animation de chargement
    }
  };

  return (
    <div className="LoginForm SignupForm">
      <div className="top">
        <img src={unimatchLogoPng} className='home-logo' alt="logo unimatch" />
      </div>
      <form onSubmit={handleSubmit}>
        <h1>S'inscrire</h1>
        <p className='error-msg'>{errorMessage}</p>
        <select
          name="accountType"
          value={formData.accountType}
          onChange={handleChange}
          required
        >
          <option value="">Type de compte</option>
          <option value="business">Entreprise</option>
          <option value="student">Étudiant</option>
        </select>
        <TextInput
          labelText={formData.accountType === "business" ? "Nom de l'entreprise" : `Nom complet`}
          name="fullName"
          type="text"
          placeholder={formData.accountType === "business" ? "Nom de l'entreprise" : `Nom complet`}
          value={formData.fullName}
          onChange={handleChange}
          isRequired={true}
        />
        <TextInput
          labelText="Adresse email"
          infoText={'Cette adresse email apparaîtra sur votre profil comme information de contact.'}
          name="email"
          type="email"
          placeholder="Courriel"
          value={formData.email}
          onChange={handleChange}
          isRequired={true}
        />
        <TextInput
          labelText="Numéro de téléphone"
          name="phoneNumber"
          type="phone"
          placeholder="123-456-7890"
          value={formData.phoneNumber}
          onChange={handleChange}
          isRequired={true}
        />
        <TextInput
          labelText="Mot de passe"
          name="password"
          type="password"
          placeholder="Mot de passe"
          value={formData.password}
          onChange={handleChange}
          isRequired={true}
        />
        <TextInput
          labelText="Confirmer le mot de passe"
          name="confirmPassword"
          type="password"
          placeholder="Mot de passe"
          value={formData.confirmPassword}
          onChange={handleChange}
          isRequired={true}
        />
        <button type='submit' disabled={isLoading}>
          {isLoading ? 'Chargement...' : "S'inscrire"}
        </button>
      </form>
      <div className='loginBox'>
        <h2>Déjà un compte ?</h2>
        <Button route="/login" type="" text="Se Connecter"></Button>
      </div>
    </div>
  );
}

export default SignUpForm;
