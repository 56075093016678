import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/Accueil.css';
import { unimatchLogoPng, tecnovaBanner, logoComite } from '../img/index';

function Accueil() {
  const navigate = useNavigate();

  return (
    <div className='Accueil'>
        <p className="tecnova"><img className='logo-comite' src={logoComite} alt="" /></p>
        <div className="top">
          <img src={unimatchLogoPng} className='home-logo' alt="logo unimatch" />
          <h1 className='appName'>UniMatch</h1>
        </div>
        

        <div className="bottom">
          <button onClick={() => navigate("/connexion")} style={styles.button}>Se Connecter</button>
          <button onClick={() => navigate("/inscription")} style={styles.buttonSignup}>Inscription</button>
          <p className="tecnova mt-2">Propulsé par <img src={tecnovaBanner} alt="" /></p>
        </div>
    </div>
  )
} 

export default Accueil


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    height: '100vh', // Prend toute la hauteur de la fenêtre
  },
  header: {
    marginBottom: '20px',
  },
  button: {
    margin: '10px',
    border: 'none',
    borderRadius: '20px',
    background: 'var(--main-red)',
    color: 'white'
  },
  buttonSignup: {
    margin: '10px',
    border: 'none',
    borderRadius: '20px',
    background: 'var(--main-yellow)',
    color: 'white'
  }
};
