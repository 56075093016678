import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'
import "../../CSS/TextInput.css";

function TextInput({ labelText, name, type, placeholder, value, onChange, maxLength, infoText, isRequired }) {
  const [showPassword, setShowPassword] = useState(false);


  return (
    <div className="text-input">
      <label htmlFor={name}>{labelText}</label>
      <p className='info-text'>{infoText}</p>
      <div className='password-container'>
        <input
          type={type !== "password" ? type : (showPassword ? "text" : "password")}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          required={isRequired}
          ShowPassword={true}
        />
        {type === 'password' ? 
            <div className="showmdp-icon" onClick={() => setShowPassword(!showPassword)}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </div>
          :
            ''
        }
      </div>
    </div>
  );
}

export default TextInput;
