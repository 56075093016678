import React, { useEffect, useState } from 'react';
import { LoadingScreen, Navbar } from '../components/indexBasicsComponents';
import '../CSS/Profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { tecnovaBanner, logoComite } from '../img/index';
import {useAuth} from '../context/AuthContext';
import config from '../settings.json'; 

import axios from 'axios';


import { useParams, useLocation } from 'react-router-dom';



function Profil() {
  const [userProfile, setUserProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const userStr = localStorage.getItem("user");
  const userObj = JSON.parse(userStr);
  const token = userObj ? userObj.token : null;
  const [isOwnProfil, setIsOwnProfil] = useState(false);

  const { businessId, studentId } = useParams(); // Récupère les paramètres de l'URL
  const location = useLocation(); // Récupère l'objet location actuel
  const { logout } = useAuth();


  useEffect(() => {
    // Déterminez si vous êtes sur le profil de l'utilisateur, d'une entreprise ou d'un étudiant
    if (location.pathname.startsWith("/profil/entreprise")) {
      fetchBusinessProfile(businessId);
    } else if (location.pathname.startsWith("/profil/etudiant")) {
      fetchStudentProfile(studentId);
    } else {
      setIsOwnProfil(true);
      const storedProfile = localStorage.getItem('userProfile');
      if (storedProfile) {
        setUserProfile(JSON.parse(storedProfile));
        setEditedProfile(JSON.parse(storedProfile)); // Si vous voulez pré-remplir les champs en mode édition
      } else {
        // Si pas de profil enregistré, fetch le profil depuis l'API
        fetchUserProfile();
      }
    }
  }, [businessId, studentId, location.pathname]);

  const fetchBusinessProfile = async (id) => {
    // Essayez de récupérer les données de toutes les entreprises stockées dans le localStorage
    const storedEntreprises = JSON.parse(localStorage.getItem('entreprises')) || [];
    // Cherchez si l'entreprise demandée est déjà dans le localStorage
    const storedEntreprise = storedEntreprises.find(entreprise => entreprise.id.toString() === id);
  
    if (storedEntreprise) {
      // Si l'entreprise est trouvée dans le localStorage, utilisez ces données
      /* console.log('Donnée récupérée du localStorage : ', storedEntreprise); */
      setUserProfile(storedEntreprise);
    } else {
      // Si l'entreprise n'est pas trouvée, faites la requête à l'API
      try {
        const response = await fetch(`${config.apiBaseUrl}/business/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          /* console.log('Donnée reçue de l\'API : ', data); */
          setUserProfile(data);
          // Ajoutez l'entreprise aux données stockées et mettez à jour le localStorage
          storedEntreprises.push(data);
          localStorage.setItem('entreprises', JSON.stringify(storedEntreprises));
        } else {
          alert("Impossible de récupérer les informations de l'entreprise");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du profil de l'entreprise :", error);
      }
    }
  };
  
  const fetchStudentProfile = async (id) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/student/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        setUserProfile(data);
      } else {
        alert("Impossible de récupérer les informations de l'étudiant");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du profil de l'étudiant :", error);
    }
  };


  const fetchUserProfile = async () => {
    const response = await fetch(`${config.apiBaseUrl}/user/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    if (response.ok) {
      setUserProfile(data);
      setEditedProfile(data); // Initialise le profil édité avec les données actuelles
      /* console.log("DATA RECU : ", data); */
    } else {
      alert("Impossible de récupérer les informations du profil");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => { // Nouveau
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
      // Vous pouvez également mettre à jour editedProfile ici si nécessaire
    }
  };

  const saveProfile = async () => {
    const formData = new FormData();
  
    // Vérifiez si un fichier a été sélectionné et ajouté à formData
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput && fileInput.files[0]) {
      formData.append('profileImage', fileInput.files[0]);
    }
  
    // Ajoutez les autres champs de profil à formData
    Object.keys(editedProfile).forEach(key => {
      if (key !== 'profileImageUrl') { // Excluez l'URL de l'image, car vous envoyez le fichier lui-même
        formData.append(key, editedProfile[key]);
      }
      /* console.log(formData); */
    });
  
    try {
      const response = await fetch(`${config.apiBaseUrl}/${userObj.userType}/${userProfile.id}`, {
        method: 'PUT',
        headers: {
          // Ne définissez pas 'Content-Type': 'application/json' ici,
          // car FormData utilise 'multipart/form-data' et le navigateur le définira automatiquement
          'Authorization': `Bearer ${token}`,
        },
        body: formData, // Envoyez formData au lieu d'un objet JSON
      });
  
      if (response.ok) {
        const updatedProfile = await response.json();
        setUserProfile(updatedProfile);
        setEditMode(false);
        setSelectedImage(null); // Réinitialisez l'image sélectionnée
    
        // Mettre à jour le localStorage avec le profil mis à jour
        localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
      } else if (response.status === 400) {
        const errorData = await response.json();
        if (errorData.message.includes("adresse email est déjà utilisée")) {
          alert("L'adresse email est déjà utilisée par un autre compte.");
        } else {
          alert("Erreur lors de la mise à jour du profil.");
        }
      } else {
        alert("Erreur lors de la mise à jour du profil.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
      alert("Erreur lors de la mise à jour du profil");
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.')) {
      try {
        /* console.log(userObj.userType); */
        await axios.delete(`${config.apiBaseUrl}/user/${userObj.userId}?userType=${userObj.userType}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        logout();
        alert('Compte supprimé avec succès.');
        // Logique pour déconnecter l'utilisateur ou le rediriger vers la page d'accueil
      } catch (error) {
        console.error('Erreur lors de la suppression du compte:', error);
        alert('Erreur lors de la suppression du compte.');
      }
    }
  };


  if (!userProfile) {
    return <LoadingScreen />;
  }

  return (
<div className=' Profil'>
  <Navbar/>
  <div className="upper-profil">
{/*     <button onClick={handleDeleteAccount} className="btn btn-danger">Supprimer mon compte</button>
 */}    <div className="row justify-content-center mb-4">
      <div className="col-12 d-flex justify-content-end">
        {isOwnProfil &&
          (editMode ? 
            <button onClick={saveProfile} className='btn rouge-bg text-white'>Enregistrer</button>
          :
            <button onClick={() => setEditMode(true)} className='btn rouge-bg text-white'>Modifier le profil</button>)
        }
      </div>
    </div>
    <div className="row align-items-center">
      <div className={`col-md-4 text-center pdp-container ${editMode ? 'editing' : ''}`}>
        <img src={selectedImage || userProfile.profileImageUrl === null ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" : `https://backend.unimatch.ca/${userProfile.profileImageUrl}`} alt="Profile" className="img-fluid rounded-circle mb-3" />
        {userObj.userType === 'business' && <span className='pin-container'>PIN : {userProfile.pinCode}</span>}
        {editMode && (
          <div className="mb-3">
            <input type="file" className="form-control" onChange={handleImageChange} />
          </div>
        )}
      </div>
      <div className="col-md-8 ">
        <h2>{!isOwnProfil ? userProfile.businessName || userProfile.fullName : editedProfile.fullName || editedProfile.businessName}</h2>

        <div className="contact-info mb-3">
          <div className="d-flex align-items-center justify-content-center mb-2">

            {editMode ? 
              <div className="input-group mb-3">
                <span className="input-group-text">@</span>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Adresse email"
                  value={editedProfile.email || ''}
                  onChange={handleChange}
                />
              </div>
              :
              <>
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              <span><a href={`mailto:${userProfile.email}`}>{userProfile.email || "Email non spécifié"}</a></span>
              </>
            }

          </div>
          <div className="d-flex align-items-center justify-content-center mb-2">

            {editMode ? 
              <div className="input-group mb-3">
                <span className="input-group-text"><FontAwesomeIcon icon={faPhone} /></span>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  placeholder="Numéro de téléphone"
                  value={editedProfile.phoneNumber || ''}
                  onChange={handleChange}
                />
              </div>
              :
              <>
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              <span><a href={`tel:${userProfile.phoneNumber}`}>{userProfile.phoneNumber || "Téléphone non spécifié"}</a></span>
              </>
            }
          </div>
        </div>
        {editMode ? (
          <input type="text" className="form-control mb-3" name="Role" value={editedProfile.Role} onChange={handleChange} />
        ) : (
          <p className="lead">{userProfile.Role || "Rôle non spécifié"}</p>
        )}
        <div className="social-links mb-3">
          {editMode ? (
            <>
              <div className="input-group mb-3">
                <span className="input-group-text"><FontAwesomeIcon icon={faLinkedin} /></span>
                <input
                  type="text"
                  className="form-control"
                  name="linkedinLink"
                  placeholder="LinkedIn"
                  value={editedProfile.linkedinLink || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text"><FontAwesomeIcon icon={faFacebook} /></span>
                <input
                  type="text"
                  className="form-control"
                  name="facebookLink"
                  placeholder="Facebook"
                  value={editedProfile.facebookLink || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <span className="input-group-text"><FontAwesomeIcon icon={faInstagram} /></span>
                <input
                  type="text"
                  className="form-control"
                  name="instagramLink"
                  placeholder="Instagram"
                  value={editedProfile.instagramLink || ''}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center social-links">
              {userProfile.linkedinLink && userProfile.linkedinLink !== 'null' ?  <a href={userProfile.linkedinLink} className="btn btn-link"><FontAwesomeIcon icon={faLinkedin} /></a> : ''}
              {userProfile.facebookLink && userProfile.facebookLink !== 'null' ? <a href={userProfile.facebookLink} className="btn btn-link"><FontAwesomeIcon icon={faFacebook} /></a> : ''}
              {userProfile.instagramLink && userProfile.instagramLink !== 'null' ? <a href={userProfile.instagramLink} className="btn btn-link"><FontAwesomeIcon icon={faInstagram} /></a> : ''}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  <div className="row mt-4">
    <div className="col-12 mb-5">
      <h2>Description</h2>
      {editMode ? (
        <textarea
          className="form-control"
          name="description"
          value={editedProfile.description}
          onChange={handleChange}
        />
      ) : (
        <p>{userProfile.description || "Pas de description disponible."}</p>
      )}
    </div>
    <p className="tecnova">Propulsé par <img src={tecnovaBanner} alt="" /> &nbsp; et &nbsp;<img className='logo-comite' src={logoComite} alt="" /></p>

  </div>
</div>



  );
}

export default Profil;
