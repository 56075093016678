import React, { createContext, useContext, useState, useEffect } from 'react';
import { RiseLoader } from 'react-spinners';
import { LoadingScreen } from '../components/indexBasicsComponents';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Ajout d'un état de chargement

  useEffect(() => {
    // Vérifier le localStorage pour les informations d'authentification au démarrage
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setCurrentUser(user);
    }
    setLoading(false); // Mise à jour de l'état de chargement après la vérification
  }, []);

  const login = (userData) => {
    setCurrentUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.clear(); // Cela supprimera toutes les données du localStorage
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, loading }}>
        {loading ? 
            <div className='loading-screen'>
                <LoadingScreen/>
                Chargement...
            </div>
            :
            children
        }
    </AuthContext.Provider>
  );
};